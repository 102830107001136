import React from 'react';
import PropTypes from 'prop-types';
import connect from 'tools/connect';

import {LocalLoader} from 'atol-atoms/Loader';

const VncFrame = ({
    sessionId,
    password,
    isLoading,
    host, port}) => {
    const iframeRef = React.useRef();
    React.useEffect(() => {
        if (iframeRef.current) iframeRef.current.contentWindow.focus();
    }, [iframeRef]);
    return (
        <React.Fragment>
            {isLoading && (
                <div className="VncContainer__frameLoader">
                    <LocalLoader desc="Загрузка" local />
                </div>
            )}
            <iframe
                ref={iframeRef}
                title="vncContainer"
                className="VncContainer__iframe"
                src={`${
                    process.env.PUBLIC_URL
                }/vnc/vnc.html?sessionId=${sessionId}&password=${password}&host=${host}&port=${port}&resize=scale`}
            />
        </React.Fragment>
        
        
    );
};

VncFrame.propTypes = {
    sessionId: PropTypes.number,
    password: PropTypes.string,
    host: PropTypes.string,
    port: PropTypes.string,
    isLoading: PropTypes.bool,
    
};

export default connect(({vnc: {
    sessionId,
    password, host, isLoading, port}}) => ({
    sessionId,
    password,
    isLoading,
    host,
    port}))(VncFrame);
