import React from 'react';
import connect from 'tools/connect';
import PropTypes from 'prop-types';
import Popup from 'atol-atoms/Popups/Popup';
import Spacer from 'atol-atoms/Spacer';
import Button from 'atol-atoms/Button';
import IconStatus from 'atol-atoms/IconStatus';
import Typography from 'atol-atoms/Typography';

const VncError = ({vnc: {error, resetError}}) => {
    return (
        <Popup active={Boolean(error)} size="small" onClose={resetError}>
            <div className="VncContainer__popup">
                <IconStatus size={88} icon="Attantion" status="negative" />
                <Spacer size={24} />
                <Typography type="subheader">Ошибка</Typography>
                <Spacer size={16} />
                <Typography>{error}</Typography>
                <Spacer size={24} />
                <div className="VncContainer__popupConfim">
                    <Button
                        type="primary-filled"
                        onClick={resetError}
                        className="VncContainer__popupConfimButton"
                    >
  Продолжить
                    </Button>
                </div>
            </div>
        </Popup>
    );
};


VncError.propTypes = {
    vnc: PropTypes.object,
};

export default connect(({vnc}) => ({
    vnc,
}))(VncError);
