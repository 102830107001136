import React from 'react';
import PropTypes from 'prop-types';
import connect from 'tools/connect';
import {withRouter} from 'react-router-dom';
import useKeyDown from 'atol-atoms/tools/hooks/useKeyDown';
import {ENTER_KEY_CODE} from 'constants/keyCodes';
import Row from 'atol-atoms/Grids/Row';
import Cell from 'atol-atoms/Grids/Cell';
import Block from 'atol-atoms/Block';
import Spacer from 'atol-atoms/Spacer';
import ContainerLoader from 'components/ContainerLoader';
import Input from 'atol-atoms/Inputs/Input';
import Typography from 'atol-atoms/Typography';
import Button from 'atol-atoms/Button';
import Popup from 'atol-atoms/Popups/Popup';
import VncImage from 'images/vnc.png';

const VncLogin = ({
    vnc: {
        isLoading,
        serialId,
        setSerialId,
        passwordError,
        password,
        setPassword,
        canStart,
        start,
        setIsFromCctCard,
    },
    match: {params},
}) => {
    React.useEffect(() => {
        if (params.serialId) {
            setSerialId(params.serialId);
            setIsFromCctCard(true);
        }
        return () => {};
    }, []);
    useKeyDown(ENTER_KEY_CODE, start);
    const [isHowToOpen, setIsHowToOpen] = React.useState(false);
    return (
        <React.Fragment>
            <Row bottomPadding={16}>
                <Cell>
                    <Typography type="headline" className="VncContainer__loginTitle">Удаленный доступ<div className="VncContainer__beta">Beta</div></Typography>
                </Cell>
            </Row>
            <Row>
                <Cell>
                    <ContainerLoader isActive={isLoading}>
                        <Block type="block2" className="VncContainer__loginBlock">
                            <Typography block type="title">
                Подключение к устройству
                            </Typography>
                            <Spacer size={16} />
                            <Input
                                title="Серийный номер устройства"
                                placeHolder=""
                                value={serialId}
                                onChange={setSerialId}
                            />
                            <Spacer size={16} />
                            <Input
                                error={passwordError}
                                title="Пароль"
                                placeHolder=""
                                value={password}
                                onChange={setPassword}
                            />
                            <Spacer size={16} />
                            <div className="VncContainer__connectionButtonContainer">
                                <Button
                                    disabled={!canStart}
                                    type="primary-filled"
                                    onClick={start}
                                    className="VncContainer__connectButton"
                                >Подключиться
                                </Button>
                            </div>
                        </Block>
                    </ContainerLoader>
                </Cell>
                <Cell className="VncContainer">
                    <Typography block type="body2">
            С помощью удаленного доступа вы сможете видеть экран и управлять
            устройством у клиента.
                    </Typography>
                    <Spacer size={16} />
                    <Button className="VncContainer__buttonLink" type="empty" onClick={() => setIsHowToOpen(true)}>Инструкция подключения к устройству</Button>
                    <Spacer size={8} />
                    <div className="VncContainer__logo">
                        <img src={VncImage} alt="VNC logo" />
                    </div>
                </Cell>
            </Row>
            <Popup size="small" active={isHowToOpen} onClose={() => setIsHowToOpen(false)}>
                <Typography type="subheader">Как подключиться к устройству</Typography>
                <div className="VncContainer__howTo">
                    <div className="VncContainer__howToNumber">1</div>
                    <Typography>Связаться с клиентом (проще всего будет по телефону).</Typography>
                </div>
                <div className="VncContainer__howTo">
                    <div className="VncContainer__howToNumber">2</div>
                    <Typography> Попросить клиента запустить приложение «Удаленный доступ» на его
          устройстве и нажать кнопку «Создать соединение»
                    </Typography>
                </div>
                <div className="VncContainer__howTo">
                    <div className="VncContainer__howToNumber">3</div>
                    <Typography>Узнать у клиента параметры подключения.</Typography>
                </div>
                <div className="VncContainer__howTo">
                    <div className="VncContainer__howToNumber">4</div>
                    <Typography> Ввести параметры подключения в форме и нажать «Подключиться».</Typography>
                </div>
                <div className="VncContainer__howToConfirmBlock">
                    <Button className="VncContainer__howToConfirm" type="primary-filled" onClick={() => setIsHowToOpen(false)}>Закрыть</Button>
                </div>
                
            </Popup>
        </React.Fragment>
    );
};

VncLogin.propTypes = {
    vnc: PropTypes.object,
    match: PropTypes.object,
};

export default withRouter(connect(({vnc}) => ({vnc}))(VncLogin));
