if (!window.__$config) window.__$config = {};

const defaults = {
    development: {
        api: 'https://ac-backend-vnc-management-server-master.dp.atol.tech',
    },
    staging: {
        api: 'https://ac-backend-as-lk-backend-staging.as.atol.tech/',
    },
    production: {
        api: 'https://as.atol.ru/',
    },
};

export const environment = window.__$config.ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT || 'development';

const config = {
    api: window.__$config.API || process.env.REACT_APP_API || defaults[environment].api,
    isProduction: environment === 'production',
};

export default config;
