import React from 'react';
import PropTypes from 'prop-types';
import {LocalLoader} from 'atol-atoms/Loader';
import './style.scss';

const ContainerLoader = ({isActive = false, children, className = ''}) => {
    if (!children) return null;

    const containerRef = React.useRef(null);

    const boundingClientRect = containerRef.current
        ? containerRef.current.getBoundingClientRect()
        : {};

    return (
        <React.Fragment>
            <div
                ref={containerRef}
                className={`ContainerLoader__parentNode ${
                    isActive ? 'ContainerLoader__parentNode_transparent' : ''
                } ${className}`}
                onClick={(e) => isActive && e.preventDefault()}
            >
                {children}
            </div>
            {isActive && containerRef.current && (
                <div
                    className="ContainerLoader"
                    style={{
                        left: boundingClientRect.width / 2,
                        top: boundingClientRect.height / 2,
                    }}
                >
                    <LocalLoader active desc="Загрузка" local />
                </div>
            )}
        </React.Fragment>
    );
};

ContainerLoader.propTypes = {
    isActive: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default ContainerLoader;
