import {types, applySnapshot} from 'mobx-state-tree';
import Common from './common';
import Vnc from './vnc';

const Store = types
    .model('Store', {
        common: types.optional(Common, {}),
        vnc: types.optional(Vnc, {}),
    })
    .actions((self) => {
        const store = self;

        const reset = () => {
            applySnapshot(store.vnc, {});
        };

        const afterCreate = () => {
            const localStorageState = localStorage.getItem('storeState');
            if (!/({)(.*)(})/.test(localStorageState)) return;
            localStorage.removeItem('storeState');
            const state = JSON.parse(localStorageState);
            const {vnc} = state;
            applySnapshot(store.vnc, vnc);
        };

        const saveState = () => {
            localStorage.setItem('storeState', JSON.stringify(store.toJSON()));
        };
        return {reset, saveState, afterCreate};
    });

export default Store;
