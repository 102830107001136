import React from 'react';
import PropTypes from 'prop-types';
import connect from 'tools/connect.js';
import Container from 'atol-atoms/Grids/Container';

import VncLogin from './VncLogin';
import VncFrame from './VncFrame';
import VncError from './VncError';
import VncDisconnectDialog from './VncDisconnectDialog';
import './styles.scss';

export function VncContainer({vnc: {error, host, port, handleVncMessage}}) {
    React.useEffect(() => {
        window.addEventListener('message', handleVncMessage, false);
        return () => {
            window.removeEventListener('message', handleVncMessage, false);
        };
    }, []);

    React.useEffect(() => {
        window.onbeforeunload = () => (host !== null && port !== null ? false : null);
        return () => {
            window.onbeforeunload = null;
        };
    });

    return (
        <Container fluid className="VncContainer">
            {host !== null && port !== null && error === null ? <VncFrame /> : <VncLogin />}
            <VncError />
            <VncDisconnectDialog />
        </Container>
    );
}

VncContainer.propTypes = {
    vnc: PropTypes.object,
};

export default connect(({vnc}) => ({
    vnc,
}))(VncContainer);
