import {types, flow} from 'mobx-state-tree';
import api from 'api';

const Vnc = types
    .model('Vnc', {
        isLoading: types.optional(types.boolean, false),
        sessionId: types.maybeNull(types.number),
        password: types.optional(types.string, ''),
        serialId: types.optional(types.string, ''),
        passwordError: types.optional(types.string, ''),
        host: types.maybeNull(types.string),
        port: types.maybeNull(types.string),
        error: types.maybeNull(types.string),
        isDisconnectDialogOpen: types.optional(types.boolean, false),
        isFromCctCard: types.optional(types.boolean, false),
    })
    .actions((self) => {
        const vnc = self;

        const resetError = () => {
            vnc.error = null;
            vnc.password = '';
            vnc.host = null;
            vnc.host = null;
            vnc.isLoading = false;
        };

        const checkPassword = () => {
            if (
                !(
                    /^\d+$/.test(vnc.password)
          && vnc.password >= 100000
          && vnc.password <= 2147483648
                )
            ) {
                vnc.passwordError = 'Неправильный пароль';
            } else {
                vnc.passwordError = '';
            }
        };

        const start = flow(function* ajax() {
            checkPassword();
            if (vnc.passwordError) {
                return;
            }
            vnc.isLoading = true;
            try {
                const {data} = yield api.post('api/vnc/client/get_slot_client', {
                    body: {
                        serial_id: vnc.serialId,
                    },
                });

                if (data.clientSlot) {
                    const [host, port] = data.clientSlot.split(':');
                    vnc.host = host;
                    vnc.port = port;
                    vnc.sessionId = data.sessionId;
                } else {
                    vnc.error = 'Не удалось авторизоваться';
                }
            } catch (error) {
                vnc.error = error.message;
            } finally {
                vnc.isLoading = false;
            }
        });

        const stop = flow(function* ajax() {
            vnc.isLoading = true;
            vnc.toggleDisconnectDialog(false);
            try {
                yield api.post('api/vnc/client/release_slot_client', {
                    body: {
                        serial_id: vnc.serialId,
                    },
                });
            } finally {
                vnc.host = null;
                vnc.port = null;
                vnc.sessionId = null;
                vnc.password = '';
                vnc.isLoading = false;
            }
        });

        const toggleDisconnectDialog = (state) => {
            vnc.isDisconnectDialogOpen = state;
        };

        const handleVncMessage = (e) => {
            const message = typeof e.data === 'string' && (/({)(.*)(})/.test(e.data)) ? JSON.parse(e.data) : {};
            if (message.m_type === 'connected') {
                vnc.isLoading = false;
            }
            if (message.m_type === 'connecting') vnc.isLoading = true;
            if (message.m_type === 'reconnecting') vnc.isLoading = true;
            if (message.m_type === 'disconnecting') vnc.isLoading = true;

            if (message.m_type === 'disconnected') {
                vnc.isLoading = false;
                vnc.error = 'Соединение разорвано';
            }
            if (message.m_type === 'disconnect') {
                vnc.toggleDisconnectDialog(true);
            }
            if (message.m_type === 'wrongpass') {
                vnc.isLoading = false;
                vnc.error = 'Неправильный пароль';
            }
        };

        const setIsFromCctCard = (state = true) => (vnc.isFromCctCard = state);

        const setPassword = (e) => {
            vnc.password = e.target ? e.target.value : e;
        };

        const setSerialId = (e) => {
            vnc.serialId = e.target ? e.target.value : e;
        };

        return {
            checkPassword,
            start,
            stop,
            setSerialId,
            setPassword,
            handleVncMessage,
            resetError,
            toggleDisconnectDialog,
            setIsFromCctCard,
        };
    })
    .views((vnc) => ({
        get canStart() {
            return Boolean(vnc.serialId && vnc.password);
        },
    }));

export default Vnc;
