import {types} from 'mobx-state-tree';
import Notification from './notification';

const Common = types
    .model('Common', {
        notifications: types.array(Notification),
    })
    .actions((self) => {
        const common = self;
        const addNotification = (notification) => {
            const notificationText = notification instanceof Error ? notification.message : notification;
            common.notifications.push({content: notificationText === 'Failed to fetch' ? 'Произошла неизвестная ошибка' : notificationText});
        };

        const updateNotification = ({id, action}) => {
            if (action === 'notificationClosed') common.removeNotifiocation(id);
        };

        const removeNotification = (id) => (common.notifications = common.notifications.filter((notification) => notification.id !== id));

        return {addNotification, updateNotification, removeNotification};
    });

export default Common;
