import 'core-js';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import 'atol-atoms/global-style.css';
import {Router} from 'react-router-dom';
import history from 'tools/history';
import Store from 'stores';
import {setStore} from 'tools/connect';
import makeInspectable from 'mobx-devtools-mst';
import config, {environment} from 'configs';
import {init as Sentry} from '@sentry/browser';
import browserControls from './tools/browsers-control';
import AppContainer from './containers/AppContainer';

if (environment !== 'development') {
    Sentry({
        dsn: config.sentryUrl,
    });
}

const store = Store.create({});
if (environment !== 'production') {
    window.store = store;
    makeInspectable(store);
}
setStore(store);

// import * as serviceWorker from './serviceWorker';

export const browserVerify = browserControls.browserVerify();

const APP_ROOT_ID = 'application_root';

export function renderApp(App) {
    ReactDOM.render(
        <React.Fragment>
            <Router history={history}>
                <App />
            </Router>
        </React.Fragment>,
        document.getElementById(APP_ROOT_ID)
    );
}

if (browserVerify.isNotAllowedBrowser) {
    document.body.appendChild(browserVerify.nodeElement);
} else {
    renderApp(AppContainer);
}

if (environment !== 'production' && module.hot) {
    module.hot.accept('./containers/AppContainer', () => {
        // eslint-disable-next-line
        const NextApp = require('./containers/AppContainer').default;
        renderApp(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
