import React from 'react';
import Typography from 'atol-atoms/Typography';
import {getStore} from 'tools/connect';
import {captureException, withScope} from '@sentry/browser';
import './style.scss';

class ErrorBoundary extends React.Component {
    state = {hasError: false};

    componentDidCatch(error, errorInfo) {
        const isChankLoadingError = /(Loading)(.*)(failed)/.test(error);
        if (isChankLoadingError) {
            getStore().saveState();
            window.location.reload(true);
            return;
        }

        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
        withScope((scope) => {
            scope.setTag('crash-type', 'render');
            captureException(error);
        });

        this.setState({
            hasError: true,
        });
    }

    render() {
        const {hasError} = this.state;
        if (hasError) {
            return (
                <div className="ErrorBoundary">
                    <Typography type="title">Упс, что-то пошло не так...</Typography>
                </div>
            );
        }
        // eslint-disable-next-line
        const {children} = this.props;
        return children;
    }
}

export default ErrorBoundary;
