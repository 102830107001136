import React from 'react';
import PropTypes from 'prop-types';
import connect from 'tools/connect';

import Popup from 'atol-atoms/Popups/Popup';
import Typography from 'atol-atoms/Typography';
import Spacer from 'atol-atoms/Spacer';
import Button from 'atol-atoms/Button';


const VncDisconnectDialog = ({vnc: {isDisconnectDialogOpen, stop, toggleDisconnectDialog}}) => {
    return (
        <Popup active={isDisconnectDialogOpen} size="small" className="VncDisconnectDialog">
            <Typography type="title" className="VncDisconnectDialog__headline">Завершить подключение?</Typography>
            <Spacer size={16} />
            <Typography type="subtitle">Для повторного подключения необходимо будет создать подключение заново.</Typography>
            <Spacer size={16} />
            <div className="VncDisconnectDialog__actions">
                <Button onClick={() => toggleDisconnectDialog(false)} type="primary" className="VncDisconnectDialog__actionButton">Отмена</Button>
                <Button onClick={stop} type="primary-filled" className="VncDisconnectDialog__actionButton">Завершить</Button>
            </div>
        </Popup>
    );
};

VncDisconnectDialog.propTypes = {
    vnc: PropTypes.object,
};

export default connect(({vnc}) => ({
    vnc,
}))(VncDisconnectDialog);
