import React from 'react';
import Loader from 'atol-atoms/Loader';
import VncContainer from 'containers/VncContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import {Route, withRouter} from 'react-router-dom';

import 'style/global-style.scss';

const NotificationManager = React.lazy(() => import('components/NotificationManager'));

export function AppContainer() {
    return (
        <div className="appContainer">
            <ErrorBoundary>
                <Route path="/:serialId?" component={VncContainer} />
            </ErrorBoundary>
            <React.Suspense fallback={<Loader active />}>
                <NotificationManager />
            </React.Suspense>
        </div>
    );
}
export default withRouter(AppContainer);
