import config from 'configs';
import keysToCamel from './keysToCamel';

const flattenError = (err) => {
    if (Array.isArray(err)) {
        return err.join(', ');
    }
    return Object.values(err).map(flattenError).join('\r\n');
};

export const getApiPath = (path) => {
    return `${config.api}${config.api.endsWith('/') ? '' : '/'}${path.startsWith('/') ? path.slice(1) : path}`;
};
const callApi = (path, options) => {
    const url = getApiPath(path);

    return fetch(url, {
        credentials: 'include',
        headers: options.headers ? options.headers : {'Content-type': 'application/json; charset=utf-8'},
        ...options,
    })
        .then((res) => {
            switch (options.format) {
                case 'json':
                    return res.json();
                case 'text':
                    return res.text();
                case 'blob':
                    return res.blob();
                case 'formData':
                    return res.formData();
                case 'arrayBuffer':
                    return res.arrayBuffer();
                default:
                    return res.json();
            }
        })
        .then((data) => {
            if (data.errorText && data.errorFields) {
                const errorText = flattenError(data.errorFields);
                const error = new Error(errorText);
                error.body = {...data, errorText};
                throw error;
            } else if (data.errorText) {
                const error = new Error(typeof data.errorText === 'string' ? data.errorText : 'Неизвестная Ошибка');
                error.body = data;
                throw error;
            } else {
                return options.toCamel === false ? data : keysToCamel(data);
            }
        });
};

const api = {
    get: (path, options = {}) => callApi(path, {...options, method: 'GET'}),
    post: (path, options = {}) => callApi(path, {...options, method: 'POST', body: JSON.stringify(options.body || '')}),
    patch: (path, options = {}) => callApi(path, {...options, method: 'PATCH', body: JSON.stringify(options.body || '')}),
    put: (path, options = {}) => callApi(path, {...options, method: 'PUT', body: JSON.stringify(options.body || '')}),
    delete: (path, options = {}) => callApi(path, {...options, method: 'DELETE', body: JSON.stringify(options.body || '')}),
    upload: (path, options = {}) => callApi(path, {...options, method: 'POST', body: options.body, headers: {...(options.headers || {})}}),
};

export default api;
